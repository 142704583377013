import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm_language from "src/components/trbm_language"
import Chromedisclosureint from "src/components/chromedisclosureint"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const bmData = import("./data/data.json");

let css;
  if(isChrome()){
    css = `  `;
  }
  else{
    css = `
    `;
  }


export default function trbmFr() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/laptop-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .footerlinks-module--br, .footerlinks-module--br a {
      color: #f1f1f1;
    }

    .trbm-module--trbm .trbm-module--bullets {
        padding: 0 0 0 40px;
        width: 75%;
      }
      .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }

    a.ctadisclosure-module--bold {
      font-weight: 400 !important;
    }

    .trbm-module--trbm .trbm-module--step1 {
      left: 10px;
    }

    .trbm-module--trbm .trbm-module--step2 {
      left: 147px;
    }

    .trbm-module--trbm .trbm-module--card {
      padding: 0;
    }

    .trbm-module--trbm h1 {
      font-weight: bold;
    }
    `}
    </style>

    <style type="text/css">
      {css}
    </style>

    <title>Free PDF Editor - pdftab.com</title></Helmet>
    <section>
      <Trbm_language data={bmData} language='fr'>
        <Chromedisclosureint data={bmData} language='fr'></Chromedisclosureint>
      </Trbm_language>
      </section>
    </HomepageLayout>
  )
}
